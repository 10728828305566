/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'record-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M8 13A5 5 0 108 3a5 5 0 000 10"/>',
    },
});
